import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#004b55",
        secondary: "#BDD24E",
        accent: "#DC8C30"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg"
  }
});
