<template>
  <v-app v-scroll="onScroll">
    <v-app-bar
      :color="offsetTop > 50 ? 'primary' : 'rgba(0, 0, 0, 0)'"
      fixed
      max-width="100vw"
      tile
      elevate-on-scroll
      app
    >
      <router-link :to="{ name: 'home' }">
        <v-img
          src="https://www.luontoenergia.fi/logo.png"
          :max-width="
            $vuetify.breakpoint.xsOnly
              ? 250
              : $vuetify.breakpoint.xlOnly
              ? 300
              : 275
          "
          alt="LuontoEnergia"
          @click="scrollToTop"
          contain
        />
      </router-link>
      <v-spacer />
      <v-toolbar-items v-if="$vuetify.breakpoint.xlOnly">
        <v-btn
          @click="scrollToServices"
          x-large
          text
          :to="{ name: 'company' }"
          class="font-weight-bold headline"
          color="secondary"
        >
          Yritys
        </v-btn>
        <v-btn
          @click="scrollToServices"
          x-large
          text
          :to="{ name: 'energy' }"
          class="font-weight-bold headline"
          color="secondary"
        >
          Aurinkoenergia
        </v-btn>
        <v-btn
          @click="scrollToServices"
          x-large
          text
          :to="{ name: 'products' }"
          class="font-weight-bold headline"
          color="secondary"
        >
          Tuotteet ja palvelut
        </v-btn>
        <v-btn
          @click="scrollToFunding"
          x-large
          text
          class="font-weight-bold headline"
          color="secondary"
        >
          Rahoitus
        </v-btn>
        <v-btn
          @click="scrollToServices"
          x-large
          text
          :to="{ name: 'events' }"
          class="font-weight-bold headline"
          color="secondary"
        >
          Ajankohtaista
        </v-btn>
        <v-btn
          x-large
          text
          class="font-weight-bold headline"
          color="secondary"
          @click="scrollToContacts"
        >
          Yhteydenotto
        </v-btn>
      </v-toolbar-items>
      <v-menu v-else offset-y style="box-shadow: none">
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon
            color="secondary"
            alt="menu"
            aria-label="menu"
            aria-haspopup="true"
            large
            v-on="on"
          />
        </template>
        <v-list :class="offsetTop > 50 ? 'nightBackground' : 'transparentMenu'">
          <v-list-item
            style="cursor: pointer"
            class="font-weight-bold title springText"
            :to="{ name: 'company' }"
            @click="scrollToServices"
          >
            <v-list-item-title class="font-weight-bold title springText"
              >YRITYS</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            class="font-weight-bold title springText"
            :to="{ name: 'energy' }"
            @click="scrollToServices"
          >
            <v-list-item-title class="font-weight-bold title springText"
              >AURINKOENERGIA</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            class="font-weight-bold title springText"
            :to="{ name: 'products' }"
            @click="scrollToServices"
          >
            <v-list-item-title class="font-weight-bold title springText"
              >TUOTTEET</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            class="font-weight-bold title springText"
            @click="scrollToFunding"
          >
            <v-list-item-title class="font-weight-bold title springText"
              >RAHOITUS</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            class="font-weight-bold title springText"
            :to="{ name: 'events' }"
            @click="scrollToServices"
          >
            <v-list-item-title class="font-weight-bold title springText"
              >AJANKOHTAISTA</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            class="font-weight-bold title springText"
          >
            <v-list-item-title
              class="font-weight-bold springText title"
              @click="scrollToContacts"
              >YHTEYDENOTTO</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-content>
      <section ref="carousel">
        <v-carousel
          cycle
          continuous
          class="darkBackground"
          interval="10000"
          height="100vh"
          hide-delimiters
          :show-arrows="false"
          :style="{
            'margin-top': $vuetify.breakpoint.xsOnly ? '-56px' : '-64px'
          }"
        >
          <v-carousel-item src="https://www.luontoenergia.fi/hero1.jpg">
            <v-layout
              column
              fill-height
              align-start
              justify-end
              :class="$vuetify.breakpoint.xsOnly ? 'ml-1' : 'ml-10'"
            >
              <v-card shaped raised class="nightBackground">
                <v-card-text>
                  <h1
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'title'
                        : $vuetify.breakpoint.sm
                        ? 'display-1'
                        : 'display-3'
                    "
                    class="springText font-weight-medium"
                  >
                    SÄÄSTÄ LUONTOA SÄÄSTÄMÄLLÄ
                  </h1>
                </v-card-text>
              </v-card>
              <v-btn
                x-large
                class="nightText springBackground font-weight-bold mb-12"
                @click="scrollToServices"
              >
                Lue lisää
              </v-btn>
            </v-layout>
          </v-carousel-item>
          <v-carousel-item :src="`${publicPath}/hero2.jpg`">
            <v-layout
              column
              fill-height
              align-start
              justify-end
              :class="$vuetify.breakpoint.xsOnly ? 'ml-1' : 'ml-10'"
            >
              <v-card shaped raised class="nightBackground">
                <v-card-text>
                  <h1
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'title'
                        : $vuetify.breakpoint.sm
                        ? 'display-1'
                        : 'display-3'
                    "
                    class="springText font-weight-medium"
                  >
                    SÄÄSTÄ LUONTOA SÄÄSTÄMÄLLÄ
                  </h1>
                </v-card-text>
              </v-card>
              <v-btn
                x-large
                class="nightText springBackground font-weight-bold mb-12"
                @click="scrollToServices"
              >
                Lue lisää
              </v-btn>
            </v-layout>
          </v-carousel-item>
          <v-carousel-item :src="`${publicPath}/hero3.jpg`">
            <v-layout
              column
              fill-height
              align-start
              justify-end
              :class="$vuetify.breakpoint.xsOnly ? 'ml-1' : 'ml-10'"
            >
              <v-card shaped raised class="nightBackground">
                <v-card-text>
                  <h1
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'title'
                        : $vuetify.breakpoint.sm
                        ? 'display-1'
                        : 'display-3'
                    "
                    class="springText font-weight-medium"
                  >
                    SÄÄSTÄ LUONTOA SÄÄSTÄMÄLLÄ
                  </h1>
                </v-card-text>
              </v-card>
              <v-btn
                x-large
                class="nightText springBackground font-weight-bold mb-12"
                @click="scrollToServices"
              >
                Lue lisää
              </v-btn>
            </v-layout>
          </v-carousel-item>
        </v-carousel>
      </section>
      <section ref="services">
        <router-view />
        <v-parallax :src="`${publicPath}/section.jpg`" height="380">
          <v-lazy
            v-model="parallax"
            :options="{
              threshold: 1
            }"
            min-height="200"
            transition="slide-x-transition"
          >
            <v-layout column align-center justify-center class="mt-12">
              <v-card shaped raised class="mt-12 nightBackground text-center">
                <v-card-text
                  class="springText"
                  :class="
                    this.$vuetify.breakpoint.smAndDown
                      ? 'display-1'
                      : 'display-2'
                  "
                >
                  Säästä luontoa säästämällä
                </v-card-text>
              </v-card>
              <v-btn
                rounded
                raised
                class="mt-12 nightText font-weight-bold"
                color="secondary"
                x-large
                @click="scrollToContacts"
              >
                Ota yhteyttä
              </v-btn>
            </v-layout>
          </v-lazy>
        </v-parallax>
      </section>
      <section class="nightBackground" ref="funding">
        <v-layout row wrap class="ma-0 pt-10" align-center>
          <v-flex xs12 class="ma-10">
            <h3 class="font-weight-medium display-1 springText">
              Laske tarpeisiisi sopiva rahoitus
            </h3>
          </v-flex>
          <v-flex xs12 lg5 class="mx-10 mb-10">
            <h5 class="font-weight-medium  headline springText">Lainasumma</h5>
            <h5 class="font-weight-medium  headline springText">
              {{ fundedAmount }} €
            </h5>
            <v-slider
              color="#bdd24e"
              track-color="#bdd24e"
              min="1000"
              max="30000"
              step="1000"
              thumb-color="#bdd24e"
              v-model="fundedAmount"
            ></v-slider>
            <h5 class="font-weight-medium  headline springText">Maksuaika</h5>
            <h5 class="font-weight-medium  headline springText">
              {{ fundingPayback }}
              {{ fundingPayback === 1 ? " vuosi" : " vuotta" }}
            </h5>
            <v-slider
              color="#bdd24e"
              track-color="#bdd24e"
              min="1"
              max="10"
              step="1"
              thumb-color="#bdd24e"
              v-model="fundingPayback"
            ></v-slider>
          </v-flex>
          <v-flex xs12 lg5 class="mb-10">
            <h5
              class="headline font-weight-medium springText"
              :class="{ 'mx-10': $vuetify.breakpoint.lgAndDown }"
            >
              Kuukausierä:
            </h5>
            <v-card class="springBackground my-8">
              <v-card-text
                class="display-3 font-weight-medium nightText text-center"
                >{{ payment }}€ / kk</v-card-text
              >
            </v-card>
            <p class="springText text-center mx-2">
              Laskuri on suuntaa-antava. Myyjämme auttavat sinua rahoituksen
              hakemisessa.
            </p>
          </v-flex>
        </v-layout>
      </section>
      <section ref="contact">
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-12">
            <v-flex xs12 sm5>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Yhteystiedot</div>
                </v-card-title>
                <v-list
                  style="display: grid"
                  class="justify-center transparent"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="nightText">{{ phone }}</v-icon>
                    </v-list-item-action>
                    045 2622 658, 045 2622 657
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="nightText">
                        {{ marker }}
                      </v-icon>
                    </v-list-item-action>
                    Palolantie 3 E, 90620 Oulu
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="nightText">
                        {{ emailIcon }}
                      </v-icon>
                    </v-list-item-action>
                    myynti@luontoenergia.fi
                  </v-list-item>
                </v-list>
                <v-card-text class="text-center">
                  <v-layout mt-2 row justify-center align-center>
                    <v-img
                      class="elevation-2 mb-2"
                      max-width="150"
                      :src="`${publicPath}/logo3.png`"
                    />
                    <v-layout column class="ma-0" style="max-width: 18rem">
                      <p
                        class="body-1 ml-4 mr-4 mt-0 font-weight-medium nightText"
                      >
                        Löydät meidät myös Facebookista.
                      </p>
                      <v-btn
                        color="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/LuontoEnergia-Oy-100937304769137/"
                        class="mt-0 ml-4 mr-4 springText font-weight-bold"
                        ><v-icon left>{{ facebook }}</v-icon
                        >LuontoEnergia</v-btn
                      >
                    </v-layout>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm5>
              <v-card v-if="!success" flat class="transparent">
                <v-form
                  name="Yhteydenotto"
                  method="POST"
                  action="/"
                  data-netlify="true"
                  data-netlify-honeypot="role"
                  ref="form"
                  id="contact"
                  @submit.prevent="handleSubmit"
                  v-model="valid"
                  lazy-validation
                >
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Yhteydenotto</div>
                  </v-card-title>
                  <v-card-text class="text-center">
                    Voit ottaa meihin yhteyttä alla olevalla lomakkeella
                  </v-card-text>
                  <v-text-field
                    name="role"
                    class="d-none"
                    solo
                    v-model="role"
                    shaped
                    label="Roolisi"
                  />
                  <input name="form-name" class="d-none" value="Yhteydenotto" />
                  <v-text-field
                    v-model="name"
                    solo
                    name="name"
                    aria-required="true"
                    :rules="[v => !!v || 'Kirjoita nimesi']"
                    shaped
                    label="Sinun nimesi"
                  />
                  <v-text-field
                    v-model="phoneNumber"
                    solo
                    name="phoneNumber"
                    aria-required="true"
                    :rules="phoneNumberRules"
                    shaped
                    label="Puhelinnumerosi"
                  />
                  <v-text-field
                    v-model="email"
                    solo
                    name="email"
                    aria-required="true"
                    :rules="emailRules"
                    shaped
                    label="Sähköpostiosoitteesi"
                  />
                  <v-textarea
                    v-model="message"
                    solo
                    name="message"
                    aria-required="true"
                    :rules="[v => !!v || 'Kirjoita viestisi']"
                    shaped
                    auto-grow
                    label="Viestisi"
                  />
                  <div data-netlify-recaptcha="true"></div>
                  <v-card-actions>
                    <v-btn
                      raised
                      :disabled="!valid"
                      type="submit"
                      class="nightText font-weight-bold springBackground"
                    >
                      Lähetä viesti
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
              <v-card v-else>
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Viestisi on vastaanotettu!</div>
                </v-card-title>
                <v-card-text class="text-center">
                  Vastaamme viestiisi mahdollisimman pian.
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <v-footer color="primary">
        <v-layout row wrap align-center>
          <div class="springText">
            © {{ new Date().getUTCFullYear() }} LuontoEnergia Oy
          </div>
          <v-spacer />
          <div class="springText">
            Sivun suunnittelu:
            <a
              class="springText"
              href="https://medictes.fi/"
              title="Medictes Oy"
              >Medictes Oy</a
            >
          </div>
        </v-layout>
      </v-footer>
    </v-content>
    <!-- Segment Pixel - Advertiser Pixel - DO NOT MODIFY -->
    <img
      src="https://secure.adnxs.com/seg?member_id=7458&add_code=711091A&t=2"
      width="1"
      height="1"
      style="display:none"
    />
    <!-- End of Segment Pixel -->
  </v-app>
</template>

<script>
import { mdiPhone, mdiMapMarker, mdiEmail, mdiFacebookBox } from "@mdi/js";
export default {
  name: "App",
  data: () => ({
    publicPath: process.env.VUE_APP_DEPLOY_URL || "",
    phone: mdiPhone,
    marker: mdiMapMarker,
    emailIcon: mdiEmail,
    facebook: mdiFacebookBox,
    interestRates: [9, 5, 3.3, 2.8, 2.1, 1.7, 1.6, 1.5, 1.35, 1.2],
    success: false,
    offsetTop: 0,
    parallax: false,
    contact1: false,
    contact2: false,
    valid: true,
    fundedAmount: 6000,
    fundingPayback: 2,
    role: "",
    name: "",
    email: "",
    phoneNumber: "",
    emailRules: [
      v => !!v || "Kirjoita sähköpostiosoitteesi",
      v => /.+@.+\..+/.test(v) || "Tarkista sähköpostin oikeinkirjoitus"
    ],
    phoneNumberRules: [v => !!v || "Kirjoita puhelinnumerosi"],
    message: ""
  }),
  computed: {
    payment() {
      const paymentTotal = Math.round(
        this.fundedAmount * (this.interestRates[this.fundingPayback - 1] / 100)
      );
      return paymentTotal >= 50 ? paymentTotal : 50;
    }
  },
  methods: {
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    handleSubmit: async function() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        await formData.append("role", this.role);
        await formData.append("form-name", "Yhteydenotto");
        await formData.append("name", this.name);
        await formData.append("phoneNumber", this.phoneNumber);
        await formData.append("email", this.email);
        await formData.append("message", this.message);
        const result = await this.$http.post("/", formData);
        if (result.status === 200) {
          this.success = true;
          this.success = true;
        }
      }
    },
    scrollToTop() {
      this.$refs["carousel"].scrollIntoView({
        behavior: "smooth"
      });
    },
    scrollToServices() {
      this.$refs["services"].scrollIntoView({
        behavior: "smooth"
      });
    },
    scrollToContacts() {
      this.$refs["contact"].scrollIntoView({ behavior: "smooth" });
    },
    scrollToFunding() {
      this.$refs["funding"].scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style>
.springText {
  color: #bdd24e !important;
}
.nightText {
  color: #004b55 !important;
}
.autumnText {
  color: #dc8c30 !important;
}
.springBackground {
  background-color: #bdd24e !important;
}
.nightBackground {
  background-color: #004b55 !important;
}
.autumnBackground {
  background-color: #dc8c30 !important;
}
.darkBackground {
  background-color: #696969 !important;
}
.transparentMenu {
  background-color: rgba(0, 0, 0, 0) !important;
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: #004b55;
  border: 0 none #004b55;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #004b55;
}
::-webkit-scrollbar-thumb:active {
  background: #004b55;
}
::-webkit-scrollbar-track {
  background: #bdd24e;
  border: 0 none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #bdd24e;
}
::-webkit-scrollbar-track:active {
  background: #bdd24e;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
