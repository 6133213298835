var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"my-12 mx-0",attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"mt-12",attrs:{"xs12":""}},[_c('div',{staticClass:"text-center mt-12"},[_c('div',{staticClass:"mt-11 mx-2 justify-center",staticStyle:{"display":"grid"}},[_c('h2',{staticClass:"font-weight-medium display-3 nightText",staticStyle:{"word-break":"break-word"}},[_vm._v(" Valosta energiaa ")]),_c('v-divider',{staticClass:"mx-12 mt-5",staticStyle:{"border-color":"#004b55"}})],1)])]),_c('v-flex',{staticClass:"my-0",attrs:{"xs12":""}},[_c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{staticClass:"mx-0",attrs:{"row":"","wrap":"","align-start":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","xl4":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{staticClass:"nightText",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.leaf))])],1),_c('v-card-title',{staticClass:"layout justify-center",attrs:{"primary-title":""}},[_c('div',{staticClass:"display-1 nightText text-center font-weight-medium",staticStyle:{"word-break":"break-word"}},[_vm._v(" Puhdasta energiaa ")])]),_c('v-card-text',{staticClass:"title"},[_c('v-list',{staticClass:"transparent justify-center",staticStyle:{"display":"grid"}},[_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v(" Auringon tuottaman energian hyödyntämistä sähkö- tai lämpöenergiana ")],1),_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v(" Aurinkopaneeli muuttaa auringon säteilyn eli päivänvalon sähköenergiaksi ")],1),_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v(" Paneeleilla tuotettu sähkö on päästötöntä")],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","xl4":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{staticClass:"nightText",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.money))])],1),_c('v-card-title',{staticClass:"layout justify-center",attrs:{"primary-title":""}},[_c('div',{staticClass:"display-1 nightText font-weight-medium"},[_vm._v(" Taloudellinen ")])]),_c('v-card-text',{staticClass:"title"},[_c('v-list',{staticClass:"transparent justify-center",staticStyle:{"display":"grid"}},[_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v("Pienentää sähkölaskua ja nostaa kodin arvoa")],1),_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v("Pitkä käyttöikä ilman käytön aikaisia kuluja")],1),_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v("Ylituotto voidaan ohjata esim. käyttöveden lämmitykseen, sähköauton lataukseen tai myydään energiayhtiölle.")],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","xl4":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{staticClass:"nightText",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.wrench))])],1),_c('v-card-title',{staticClass:"layout justify-center",attrs:{"primary-title":""}},[_c('div',{staticClass:"display-1 nightText font-weight-medium text-center"},[_vm._v(" Huoltovapaa ")])]),_c('v-card-text',{staticClass:"title"},[_c('v-list',{staticClass:"transparent justify-center",staticStyle:{"display":"grid"}},[_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v("Ei mekaanisia kuluvia osia")],1),_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v("Puhdistuksen tarve vähäinen")],1),_c('v-list-item',{class:{
                    'justify-center': !this.$vuetify.breakpoint.xlOnly,
                    'text-center': !this.$vuetify.breakpoint.xlOnly
                  }},[(this.$vuetify.breakpoint.xlOnly)?_c('v-list-item-action',[_c('v-icon',{staticClass:"nightText"},[_vm._v(" "+_vm._s(_vm.circle)+" ")])],1):_vm._e(),_vm._v("Asennuksen jälkeen järjestelmän voi unohtaa")],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }