<template>
  <v-layout row wrap class="my-12 mx-0" align-center>
    <v-flex xs12 class="mt-12">
      <div class="text-center mt-12">
        <div class="mt-11 mx-2 justify-center" style="display: grid">
          <h2
            class="font-weight-medium display-3 nightText"
            style="word-break: break-word"
          >
            Valosta energiaa
          </h2>
          <v-divider style="border-color: #004b55" class="mx-12 mt-5" />
        </div>
      </div>
    </v-flex>
    <v-flex xs12 class="my-0">
      <v-container grid-list-xl>
        <v-layout row wrap align-start justify-center class="mx-0">
          <v-flex xs12 xl4>
            <v-card flat class="transparent">
              <v-card-text class="text-center">
                <v-icon x-large class="nightText">{{ leaf }}</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div
                  class="display-1 nightText text-center font-weight-medium"
                  style="word-break: break-word"
                >
                  Puhdasta energiaa
                </div>
              </v-card-title>
              <v-card-text class="title">
                <v-list
                  class="transparent justify-center"
                  style="display: grid"
                >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                  >
                    <v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon>
                    </v-list-item-action>
                    Auringon tuottaman energian hyödyntämistä sähkö- tai
                    lämpöenergiana
                  </v-list-item>
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                  >
                    <v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon>
                    </v-list-item-action>
                    Aurinkopaneeli muuttaa auringon säteilyn eli päivänvalon
                    sähköenergiaksi
                  </v-list-item>
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                  >
                    <v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon>
                    </v-list-item-action>
                    Paneeleilla tuotettu sähkö on päästötöntä</v-list-item
                  >
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 xl4>
            <v-card flat class="transparent">
              <v-card-text class="text-center">
                <v-icon x-large class="nightText">{{ money }}</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="display-1 nightText font-weight-medium">
                  Taloudellinen
                </div>
              </v-card-title>
              <v-card-text class="title">
                <v-list
                  class="transparent justify-center"
                  style="display: grid"
                >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                  >
                    <v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon> </v-list-item-action
                    >Pienentää sähkölaskua ja nostaa kodin arvoa</v-list-item
                  >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                    ><v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon> </v-list-item-action
                    >Pitkä käyttöikä ilman käytön aikaisia kuluja</v-list-item
                  >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                    ><v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon> </v-list-item-action
                    >Ylituotto voidaan ohjata esim. käyttöveden lämmitykseen,
                    sähköauton lataukseen tai myydään
                    energiayhtiölle.</v-list-item
                  >
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 xl4>
            <v-card flat class="transparent">
              <v-card-text class="text-center">
                <v-icon x-large class="nightText">{{ wrench }}</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="display-1 nightText font-weight-medium text-center">
                  Huoltovapaa
                </div>
              </v-card-title>
              <v-card-text class="title">
                <v-list
                  class="transparent justify-center"
                  style="display: grid"
                >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                    ><v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon> </v-list-item-action
                    >Ei mekaanisia kuluvia osia</v-list-item
                  >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                    ><v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon> </v-list-item-action
                    >Puhdistuksen tarve vähäinen</v-list-item
                  >
                  <v-list-item
                    :class="{
                      'justify-center': !this.$vuetify.breakpoint.xlOnly,
                      'text-center': !this.$vuetify.breakpoint.xlOnly
                    }"
                    ><v-list-item-action v-if="this.$vuetify.breakpoint.xlOnly">
                      <v-icon class="nightText">
                        {{ circle }}
                      </v-icon> </v-list-item-action
                    >Asennuksen jälkeen järjestelmän voi unohtaa</v-list-item
                  >
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { mdiLeaf, mdiCashMultiple, mdiWrench, mdiCircleMedium } from "@mdi/js";
export default {
  name: "home",
  data: () => ({
    leaf: mdiLeaf,
    money: mdiCashMultiple,
    wrench: mdiWrench,
    circle: mdiCircleMedium
  })
};
</script>
