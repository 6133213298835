require("intersection-observer");
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Axios from "axios";
import router from "./router";

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

new Vue({
  vuetify,
  render: h => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
