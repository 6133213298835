import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/tuotteet",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue")
  },
  {
    path: "/yritys",
    name: "company",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/Company.vue")
  },
  {
    path: "/aurinkoenergia",
    name: "energy",
    component: () =>
      import(/* webpackChunkName: "energy" */ "../views/Energy.vue")
  },
  {
    path: "/ajankohtaista",
    name: "events",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Events.vue")
  },
  {
    path: "/yhteydenotto",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_DEPLOY_URL || process.env.BASE_URL,
  routes
});

export default router;
